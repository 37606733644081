<template>
	<BaseLoading v-if="list.isLoading" />
	<div v-else>
		<BannerCreateAndFilter
			ref="create-and-filter"
			placeholder-text="Search by Product compare name or ID"
			create-button-text="Create Product compare"
			:create-link="createLink"
			:has-link-to-filter="false"
			:is-show-filter-date-range="false"
			:found="list.meta.total"
		/>
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="list.isLoading"
					:pagination="{
						pages: list.meta.lastPage,
						activePage: list.meta.currentPage,
					}"
					:fields="PRODUCT_COMPARE_TABLE_FIELDS"
					:items="list.data"
					class="table-custom cursor-pointer"
					clickable-rows
					@onRowClick="handleRowClick"
				>
					<template #status="{item}">
						<CBadge class="badge-status mx-2" :class="[ item ? 'badge-active' : 'badge-inactive']">
							{{ getStatusValue(item) }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import BannerCreateAndFilter from '@/components/BannerCreateAndFilter.vue';

import { ROUTE_NAME } from '../enums/route';
import { PRODUCT_STATUSES } from '../enums/products';

const PRODUCT_COMPARE_TABLE_FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id' },
	{ key: 'name', label: 'Product Compare', class: 'col-banner' },
	{ key: 'status', label: 'Status', class: 'col-status' },
];

export default {
	name: 'ProductCompare',

	components: {
		BannerCreateAndFilter,
	},

	data() {
		return {
			PRODUCT_COMPARE_TABLE_FIELDS,
			createLink: ROUTE_NAME.PRODUCT_COMPARE_CREATE,

			isLoading: false,
			queryParams: null,
		};
	},

	computed: {
		...mapState('productCompares', {
			list: 'list',
		}),
	},

	async created() {
		this.queryParams = this.$route?.query ?? null;
		// add params is_active for filter status
		if (this.queryParams) {
			this.queryParams.is_active = this.queryParams.is_visible;
		}
		await this.getProductCompares(this.queryParams);
	},

	methods: {
		...mapActions({
			getProductCompares: 'productCompares/getProductCompares',
		}),

		handleRowClick(id) {
			this.$router.push(`/product-compare/${id}`);
		},
		getStatusValue(status) {
			return status ? PRODUCT_STATUSES.ACTIVE : PRODUCT_STATUSES.INACTIVE;
		},
	},
};
</script>
